@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&family=Rubik+Mono+One&display=swap');

body {
  background-image: url("https://res.cloudinary.com/darnycya/image/upload/v1686665854/aOJBjk_loenua.jpg");
  background-repeat: no-repeat;
  background-color: black;
}

p {
  color: white;
}

.aboutMeContainer{
  padding-top: 75px;
  justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
}

.projectContainer {
  padding: 16px 70px;
}

.contactContainer {
  background-color: rgba(58, 57, 57, 0.358);
  padding: 30px;
  margin: 75px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.nav {
  position: fixed;
  justify-content: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.632);
  top: 0;
}

.logo-contacts {
  width: 100px;
  height: 100px;
}

.contactMeH3 {
  color: orange;
  font-size: 40px;
  font-family: 'Rubik Mono One', sans-serif;
  margin-top: -50px;
}

.contactP {
  font-size: 17px;
}

.homePageContainer{
  padding: 16px;
}

.homePageContent{
  text-align: center;
}

.homePageName{
  color: white;
  font-size: 100px;
  margin-top: 280px;
  font-family: 'Roboto Mono', monospace;
}

.slideUp{
  color: white;
  margin-top: 40px;
  font-family: 'Rubik Mono One', sans-serif;
  animation: slideUp ease .5s forwards 1.2s;
  opacity:0;
}

@keyframes slideUp {
  0% {transform: translateY(0);}
  100% {transform: translateY(-40px);opacity:1;}
}

.scrollContainer{
  margin-top: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.scrollGif{
  width: 300px;
  height: 150px;
}

.navUl {
  color: white;
  font-family: 'Rubik Mono One', sans-serif;
  font-size: 12px;
  display: flex; 
  justify-content: center;
}

.navLi {
  display: inline;
  padding: 0px 20px;
}

.projectCardsContainer{
  background-color: rgba(58, 57, 57, 0.358);
  padding: 110px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: -55px;
}

.projectCards {
  background-color: rgb(255, 255, 255);
  width: 300px;
  border-top-left-radius: 7%;
  border-top-right-radius: 7%;
}

.projectDescription{
  padding: 10px;
}

.projectH3 {
  color: orange;
  font-size: 40px;
  font-family: 'Rubik Mono One', sans-serif;
  margin-top: 30px;
}

.skills-image {
  width: 50px;
  height: 50px;
}

.projectImage {
  width: 300px;
  height: 200px;
  border-top-left-radius: 7%;
  border-top-right-radius: 7%;
}

.projectP {
  color: black;
  font-size: 13px;
}

h5 {
  color: orange;
  font-weight: bold;
  font-size: 14px;
}

.aboutMeDescription{
  background-color: rgba(58, 57, 57, 0.358);
  width: 40%;
  padding-left: 20px;
  padding-right: 20px;
}

.aboutMeH3 {
  color: orange;
  font-size: 40px;
  font-family: 'Rubik Mono One', sans-serif;
  margin-top: -25px;
}

.aboutMeP{
  font-size: 14px;
}

.avatar {
  width: 15em;
  float: left; 
  margin: 20px;
}

.skills{
  font-size: 14px;  
}

.aboutUl{
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.aboutLi {
  display: inline;
  margin: 5px;
}

.skillsp{
  display: inline;
  background-color: orange;
  color: rgb(55, 54, 54);
  padding: 4px;
  border-radius: 30px;
  font-size: 12px;
}

.workExperienceContainer {
  background-color: rgba(58, 57, 57, 0.358);
  width: 40%;
  padding-left: 20px;
  padding-right: 20px;
}

span {
  font-weight: bold;
  color: orange
}

.workExperienceP {
font-size: 13px;
}

.workExperienceH3 {
  color: orange;
  font-size: 40px;
  font-family: 'Rubik Mono One', sans-serif;
  margin-top: -25px;
}

.container {
  padding: 0;
}

.main-timeline {
  position: relative
}

.main-timeline:before {
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  background: #c6c6c6;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0
}

.main-timeline .timeline {
  margin-bottom: 40px;
  position: relative
}

.main-timeline .timeline:after {
  content: "";
  display: block;
  clear: both
}

.main-timeline .icon {
  width: 18px;
  height: 18px;
  line-height: 18px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0
}

.main-timeline .icon:before,
.main-timeline .icon:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.33s ease-out 0s
}

.main-timeline .icon:before {
  background: #fff;
  border: 2px solid #232323;
  left: -3px
}

.main-timeline .icon:after {
  border: 2px solid #c6c6c6;
  left: 3px
}

.main-timeline .timeline:hover .icon:before {
  left: 3px
}

.main-timeline .timeline:hover .icon:after {
  left: -3px
}

.main-timeline .date-content {
  width: 50%;
  float: left;
  margin-top: 22px;
  position: relative
}

.main-timeline .date-content:before {
  content: "";
  width: 36.5%;
  height: 2px;
  background: #c6c6c6;
  margin: auto 0;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0
}

.main-timeline .date-outer {
  width: 125px;
  height: 125px;
  font-size: 16px;
  text-align: center;
  margin: auto;
  z-index: 1
}

.main-timeline .date-outer:before,
.main-timeline .date-outer:after {
  content: "";
  width: 125px;
  height: 125px;
  margin: 0 auto;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.33s ease-out 0s
}

.main-timeline .date-outer:before {
  background: #fff;
  border: 2px solid #232323;
  left: -6px
}

.main-timeline .date-outer:after {
  border: 2px solid #c6c6c6;
  left: 6px
}

.main-timeline .timeline:hover .date-outer:before {
  left: 6px
}

.main-timeline .timeline:hover .date-outer:after {
  left: -6px
}

.main-timeline .date {
  width: 100%;
  margin: auto;
  position: absolute;
  top: 27%;
  left: 0
}

.main-timeline .month {
  font-size: 18px;
  font-weight: 700
}

.main-timeline .year {
  display: block;
  font-size: 30px;
  font-weight: 700;
  color: #232323;
  line-height: 36px
}

.main-timeline .timeline-content {
  width: 50%;
  padding: 20px 0 20px 20px;
  float: right
}

.main-timeline .title {
  font-size: 19px;
  font-weight: 700;
  line-height: 24px;
  margin: 0 0 15px 0
}

.main-timeline .description {
  font-size: 14px;
  margin-bottom: -50px;
}

.main-timeline .timeline:nth-child(2n) .date-content {
  float: right
}

.main-timeline .timeline:nth-child(2n) .date-content:before {
  left: 10px
}

.main-timeline .timeline:nth-child(2n) .timeline-content {
  padding: 20px 20px 20px 0;
  text-align: right
}

@media only screen and (max-width: 991px) {
  .main-timeline .date-content {
      margin-top: 35px
  }
  .main-timeline .date-content:before {
      width: 22.5%
  }
  .main-timeline .timeline-content {
      padding: 10px 0 10px 30px
  }
  .main-timeline .title {
      font-size: 17px
  }
  .main-timeline .timeline:nth-child(2n) .timeline-content {
      padding: 10px 30px 10px 0
  }
}

@media only screen and (max-width: 767px) {
  .main-timeline:before {
      margin: 0;
      left: 7px
  }
  .main-timeline .timeline {
      margin-bottom: 20px
  }
  .main-timeline .timeline:last-child {
      margin-bottom: 0
  }
  .main-timeline .icon {
      margin: auto 0
  }
  .main-timeline .date-content {
      width: 95%;
      float: right;
      margin-top: 0
  }
  .main-timeline .date-content:before {
      display: none
  }
  .main-timeline .date-outer {
      width: 110px;
      height: 110px
  }
  .main-timeline .date-outer:before,
  .main-timeline .date-outer:after {
      width: 110px;
      height: 110px
  }
  .main-timeline .date {
      top: 30%
  }
  .main-timeline .year {
      font-size: 24px
  }
  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(2n) .timeline-content {
      width: 95%;
      text-align: center;
      padding: 10px 0
  }
  .main-timeline .title {
      margin-bottom: 10px
  }
  .main-timeline .description {
    font-size: 14px;
    margin-bottom: 0px;
  }
}
@media (max-width: 800px) {

  .homePageName{
    font-size: 70px ;
    margin-top: 140px;
    padding-bottom: 20px;
  }

  .slideUp {
    font-size: 20px;
  }

  .scrollContainer{
    margin-top: 40px;
  }

  .workExperienceH3 {
    font-size: 25px;
    margin-top: -15px;
  }

  .aboutMeH3 {
    font-size: 28px;
    margin-top: -15px;
  }

  .avatar {
    width: 10em;
    float: none;
    margin: 20px;
  }

  .projectH3 {
    font-size: 25px;
    padding: 0px 50px;
  }

  .projectCardsContainer{
    padding: 60px 20px;
    margin-top: -38px;
  }

  .projectCards {
    margin: 10px;
  }

  .contactMeH3 {
    font-size: 28px;
  }

  .logo-contacts {
    width: 50px;
    height: 50px;
  }

  .navLi {
    padding: 0px;
    font-size: 9px;
  }

  .navUl {
    padding: 0px;
  }

  .homePageContainer{
    padding: 0px;
  }

  .nav {
    position: sticky;
    padding: 1px 0px;
  }

  .contactContainer {
    margin: 0px;
  }

  .workExperienceContainer {
    width: 100%;
    padding: 100px 50px;
  }

  .aboutMeDescription{
    width: 100%;
    padding: 0px 50px;
  }

  .projectContainer {
    width: 100%;
    padding: 0px;
    margin-top: 90px;
  };
};